<!-- Settings -->
<template>
    <div  v-loading="loading">
    <div class="card card-custom">
      <div class="card-body p-0">        
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                 <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- <div class="col-sm-6 col-xs-12 text-right">
             
                <b-button class="btn-sm btn-filter" v-b-toggle.collapse-1 variant="primary" id="tooltip-button-variant"><i class="fa fa-filter" aria-hidden="true"></i></b-button>
               <b-tooltip target="tooltip-button-variant" variant="primary">Column Filter</b-tooltip>
            </div> -->
            
          </div>
        </div>
       
          <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                           
              <el-table-column label="Order #" sortable width="150" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.order.id)">{{(scope.row.order) ? scope.row.order.order_number : ""}}{{ scope.row.backorder_id ? '-BO-'+scope.row.back_order.length:'' }}</a></el-tag>                
                </template>
              </el-table-column>             
              <el-table-column label="Company Name" prop="customer_name" sortable min-width="200" >
                <template slot-scope="scope">
                {{scope.row.customer.company_name?scope.row.customer.company_name:scope.row.customer.first_name+' '+(scope.row.customer.last_name==null?'':scope.row.customer.last_name)}}
                </template>
              </el-table-column>              
               <el-table-column label=" Order Status" sortable width="130" prop = "status" align="center" >
                <template slot-scope="scope">
                 
                  <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>
                </template> 
              </el-table-column>
               <el-table-column label="Payment Status" width="130" align="center">
                 <template slot-scope="scope">
                    <div v-html="getPaymentStatus(scope.row)"></div>
                </template>
              </el-table-column> 
              <el-table-column label="Delivery Date" prop="delivery_date" sortable width="120" >
                <template slot-scope="scope">
                {{scope.row.order.delivery_date|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column> 
               <!-- <el-table-column label="Delivery Document" prop="customer_name" sortable min-width="200" >
                <template slot-scope="scope">
                {{scope.row.delivery_document}}
                </template>
              </el-table-column> -->            
             
               <el-table-column label="Delivery Verified By" prop="customer_name" sortable min-width="200" >
                <template slot-scope="scope">
                {{scope.row.edit_person_name}}
                </template>
              </el-table-column>           
                <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                      <!-- <el-dropdown-item :command="3" ><i class="fa fa-envelope"></i>Send Email</el-dropdown-item>
                       <el-dropdown-item :command="4" ><i class="fa fa-download"></i>Download</el-dropdown-item>                        -->
                       <el-dropdown-item :command="7" v-if="scope.row.order.payment_status !='unpaid' && scope.row.packing_status !='Created'" ><i class="fa fa-file"></i>Generate Packing Slip</el-dropdown-item>
                       <el-dropdown-item :command="8" v-if="scope.row.packing_status =='Created'" ><i class="fa fa-file"></i>Completed</el-dropdown-item>  
                       <el-dropdown-item :command="9" v-if="scope.row.packing_status =='Created'" ><i class="fa fa-file"></i>View Packing Slip</el-dropdown-item>                                            
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[50, 100, 500, 1000]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>      
      </div>         
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {changeStatus, downloadPdf, cancelOrder,getAllProcessingorder} from "@/api/order";
//import {getAllorder} from "@/api/deliveryStatus";
import {getAllInvoice} from "@/api/invoice";
import SendEmail from '@/components/SendEmail';
import GenerateInvoice from '@/components/GenerateInvoice';
import deliveryDocument from '@/components/OrderDelivery/add_document';
import { globalEvents } from '@/utils/globalEvents'
  export default {
    name: "pos-order",
    data() {
      return {
        list: [],
        items: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 50,
        },     
        view_type:'invoice',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        invoice_list :[],     
        isIndeterminate: true,
       
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Orders", route: "/order/list" },
        { title: "Processing"},
      ]);

      globalEvents.$on('invoiceAdded', invoice => {
        this.fetchData(1);
        if (invoice) {
            this.fetchData(1);
           
        }
      });
      globalEvents.$on('document', doc => {
        this.fetchData(1);
        if (doc) {
            this.fetchData(1);
           
        }
      });
    },
    created(){
      this.fetchData(1);
      this.getInvoice();
    },
    methods:{
      viewDelivery(delivery_id){
         this.$router.push({path:'/delivery/view/'+delivery_id})
      },
        getPaymentStatus(row){
            let date = new Date(row.order.delivery_date);
            var periods = {
                month: 30 * 24 * 60 * 60 * 1000,
                week: 7 * 24 * 60 * 60 * 1000,
                day: 24 * 60 * 60 * 1000,
                hour: 60 * 60 * 1000,
                minute: 60 * 1000
            };
            var diff = Date.now() - date.getTime();
            var status = "";
            var status_msg = "";
            if(row.order.payment_status === 'partially_paid'){
                status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
            }else if(row.order.payment_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
                if (diff > periods.month) {
                    status = Math.floor(diff / periods.month) + " month ago";
                } else if (diff > periods.week) {
                    status = Math.floor(diff / periods.week) + " week ago";
                } else if (diff > periods.day) {
                    status = Math.floor(diff / periods.day) + " days ago";
                } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
            }else if(row.order.payment_status === 'paid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--success">Paid</span>';
                if(row.order.invoice.term_paid_amount > 0){
                status_msg = '<span class="el-tag el-tag--mini el-tag--success">Term</span>';
              }
            }else if(row.order.payment_status === 'unpaid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
            }

            return status_msg;
        },
      updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},
          showAndHideColumn(column_name){
        return this.$isColumnShow(column_name, this.checkedColumns);
      },
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getAllProcessingorder(params).then(response => {         
          this.list = response.data.data.data         
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
          }
 
        )},
        handleCheckAllChange(val) {
          this.checkedColumns = [];
          if(val){
              this.column_list.map((column,index) =>{
                  this.checkedColumns.push(column.value);
                  console.log(index, this.checkedColumns);
              });
          }
          this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.column_list.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
      },
        getcustomername(customer){
          if(customer != null){
               return customer.first_name+' '+customer.last_name;
          }           
        },
      viewOrder(order_id){
         this.$router.push({path:'/order/view/'+order_id})
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
        createOrder(){
        this.$router.push({path:'/order/create'})
      },
      getInvoice(){
        this.loading = true;
         getAllInvoice().then(response => {          
          this.invoice_list = response.data.data;       
          this.loading = false;
        });
      },
     
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/delivery/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/order/status/edit/'+row.id})
          }          
          else if (evt === 3){ 
                let customer = row.customer;
                let order_id = row.id;
                let type = this.view_type;

            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          } else if (evt === 4){            
            this.loading=true;
            downloadPdf(row.id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'invoice.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          }
          else if (evt === 5){
            this.$bvModal.msgBoxConfirm('Are you sure you want to order cancel ?', {
              title: 'Please Confirm',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                cancelOrder(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
          else if (evt === 6){
            this.$modal.show(GenerateInvoice, {order:row}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          
          }else if (evt === 7){
            this.$router.push({path:'/packingslip/create/'+row.id})
          }else if (evt === 8){
            //this.$router.push({path:'/invoice/view/'+row.id})
            this.$modal.show(deliveryDocument, {
              order_id : row.id,
              delivery :row}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '700px' })
          } else if(evt === 9){
           
            this.$router.push({path:'/packingslip/view/'+row.packing_id})
          
          } 
        },
    },
    
  }
</script>